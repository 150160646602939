import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Alert, Box, IconButton, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

import { updateOrgAccount } from "./../../../../store/actions";

const ComplianceAddons = (props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  console.log("props.editAccount: ", props.editAccount);

  let complianceColumns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    ...(props.editAccount.subscription_type.toLowerCase().includes("trial")
      ? [] // If it's "Trial", exclude the price column
      : [
          {
            field: "price",
            headerName: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                Price&nbsp;
                <small style={{ color: "red", fontSize: "smaller" }}>
                  (Billed Monthly)
                </small>
              </div>
            ),
            width: 200,
            valueFormatter: (params) => `$${params.value || "0.00"}`,
          },
        ]),
    {
      field: "description",
      headerName: "Description",
      width: 500,
    },
  ];

  if (props.orgLicensing.data.licenseName === "AwsMarketPlaceLicense") {
    complianceColumns = complianceColumns.filter(
      (column) => column.field !== "price"
    );
  }

  let complianceRows = [];
  if (props.complianceAddOns) {
    complianceRows = props.complianceAddOns.map((addOn, index) => ({
      id: index + 1,
      name: addOn.name,
      price: addOn.price,
      description: addOn.description,
      enabled: addOn.enabled,
    }));
  }

  const handleEditClick = () => {
    setIsEditMode((prev) => !prev);
    setSelectedRows([]); // Clear selection when toggling edit mode
  };

  const mergeAddons = (editedAddons, complianceRows) => {
    const complianceMap = complianceRows.reduce((map, addon) => {
      map[addon.id] = addon;
      return map;
    }, {});

    editedAddons.forEach((addon) => {
      complianceMap[addon.id] = addon;
    });

    const mergedAddons = Object.values(complianceMap).map((addon) => ({
      name: addon.name,
      enabled: addon.enabled,
    }));

    return mergedAddons;
  };

  const handleSaveClick = () => {
    const editedAddons = complianceRows
      .filter((addOn) => !addOn.enabled)
      .filter((addOn) => selectedRows.includes(addOn.id))
      .map((addOn) => ({ ...addOn, enabled: true }));
    const mergedAddons = mergeAddons(editedAddons, complianceRows);

    const subscriptionRequest = {
      name: props.editAccount.subscription.name,
      addons: props.editAccount.subscription.addons.map((addon) => ({
        name: addon.name,
        enabled: addon.enabled,
      })),
    };

    let accountUpdateRequest = {
      orgId: props.editAccount.orgId,
      accountNumber: props.editAccount.accountNumber,
      accountName: props.editAccount.accountName,
      accountCode: props.editAccount.accountCode,
      urlLink: props.editAccount.urlLink,
      accounttype: props.editAccount.accounttype,
      address: props.editAccount.address,
      city: props.editAccount.city,
      state: props.editAccount.state,
      zip: props.editAccount.zip,
      country: props.editAccount.country,
      email: props.editAccount.email,
      phonenumber: props.editAccount.phonenumber,
      eula: props.editAccount.eula,
      accountResourceGroups: props.editAccount.accountResourceGroups.map(
        (rg) => ({ resourcename: rg.resourcename })
      ),
      subscription: subscriptionRequest,
      complianceAddOns: mergedAddons,
      iamrole: props.editAccount.iamrole,
      externalId: props.editAccount.externalId,
    };

    console.log("accountUpdateRequest: ", accountUpdateRequest);
    props.updateOrgAccount(accountUpdateRequest);
  };

  const updateMessage = props.message;
  useEffect(() => {
    if (updateMessage) {
      setIsEditMode(false);
    }
  }, [updateMessage]);

  useState(() => {
    const alreadySelectedRows = complianceRows
      .filter((addOn) => addOn.enabled)
      .map((addOn) => addOn.id);
    setRowSelectionModel([...alreadySelectedRows, ...selectedRows]);
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Alert
        variant="outlined"
        severity="info"
        icon={false}
        action={
          <>
            {isEditMode && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={handleSaveClick}
                style={{ marginRight: 8 }}
              >
                {props.loading ? "Saving..." : "Save"}
              </Button>
            )}
            {/* Conditionally render the edit button based on orgLicensing.licenseName */}
            {props.orgLicensing.data.licenseName !==
              "AwsMarketPlaceLicense" && !props.editAccount.subscription_type.toLowerCase().includes("trial") && (
              <IconButton
                size="small"
                color="primary"
                onClick={handleEditClick}
                sx={{
                  borderRadius: "50%",
                  border: "2px solid",
                  padding: "4px",
                  color: isEditMode ? "red" : "primary",
                }}
              >
                {isEditMode ? <CancelIcon /> : <EditIcon />}
              </IconButton>
            )}
          </>
        }
      >
        Select the Compliance Standards
      </Alert>
      <DataGrid
        rows={complianceRows}
        columns={complianceColumns}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            csvOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true },
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        checkboxSelection
        disableRowSelectionOnClick
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={(ids) => {
          setSelectedRows(ids);
          setRowSelectionModel([...ids]);
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        autoHeight
        rowHeight={30} // Reduce the row height
        sx={{
          "& .MuiDataGrid-row": {
            minHeight: "30px !important", // Minimum height for rows
            maxHeight: "30px !important", // Maximum height for rows
            lineHeight: "unset !important", // Adjust line height for text
          },
          "& .MuiDataGrid-cell": {
            py: 0, // Remove padding from cells
          },
          "& .MuiDataGrid-checkboxInput": {
            opacity: isEditMode ? 1 : 0.5, // Fully visible when in edit mode, blurred when not
            pointerEvents: isEditMode ? "auto" : "none", // Disable interaction when not in edit mode
          },
        }}
      />
    </Box>
  );
};

const mapStateToProps = (state) => {
  const { accounts, organizations, reg_acct_error, account, loading, message } =
    state.orgAccount;
  return {
    accounts,
    organizations,
    reg_acct_error,
    account,
    loading,
    message,
    orgLicensing: state.licensing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateOrgAccount: (data) => {
      dispatch(updateOrgAccount(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ComplianceAddons);
